/**
 * Get Graphql Client / custom declaration of node_modules/nuxt-graphql-client/dist/runtime/composables/index.mjs
 *
 */
export const useGuimauveGqlState = () => {
    const nuxtApp = useNuxtApp();
    if (!nuxtApp._gqlState) {
        throw new Error('GQL State is not available.');
    }
    return nuxtApp?._gqlState;
};

export const getGuimauveGqlClient = (client, state) => {
    if (!state) {
        // eslint-disable-next-line no-param-reassign
        state = useGuimauveGqlState();
    }

    const clientName = client || (state.value?.default ? 'default' : Object.keys(state.value)[0]);

    return state.value[clientName].instance;
};
