<!-- eslint-disable no-template-curly-in-string -->
<script setup>
import Pristine from 'pristinejs';

const activePage = ref(0);
const formPages = ref([]);
const error = ref(false);
const loading = ref(false);
const alertState = ref(null);
const validator = ref(null);
const formElement = ref(null);
const config = ref({});

const gqlClient = getGuimauveGqlClient();

// Assume `handle` is a prop or a reactive ref you need to define based on your requirements
const props = defineProps({
    handle: {
        type: String,
        required: true,
    },
});

const { data } = await useAsyncGql({
    operation: 'FetchFormieForm',
    variables: { handle: props.handle },
});

config.value = data?.value?.form?.configJson ? JSON.parse(data.value.form.configJson) : {};

// Computed property for stepPercentage
const stepPercentage = computed(() => (
    data.value.form && data.value.form.pages ? parseInt(((activePage.value + 1) / data.value.form.pages.length) * 100, 10) : 0));

// Methods
function onTabClick(index) {
    activePage.value = index;
}

// function onSuccess(response) {
function onSuccess() {
    if (config.value.settings.submitAction === 'message') {
        alertState.value = 'success';
    }
    formElement.value.reset();
}

// function onSubmit(e) {
const onSubmit = async () => {
    loading.value = true;
    alertState.value = false;

    const $form = formElement.value;
    const $page = formPages.value[activePage.value];
    Pristine.addMessages('fr', {
        required: 'Ce champ est requis',
        email: 'Ce champ nécessite une adresse e-mail valide',
        number: 'Ce champ nécessite un nombre',
        integer: 'Ce champ nécessite une valeur entière',
        url: 'Ce champ nécessite une URL de site Web valide',
        tel: 'Ce champ nécessite un numéro de téléphone valide',
        maxlength: 'La longueur de ce champ doit être < ${1}',
        minlength: 'La longueur de ce champ doit être > ${1}',
        min: 'La valeur minimale pour ce champ est ${1}',
        max: 'La valeur maximale pour ce champ est ${1}',
        pattern: 'Veuillez respecter le format demandé',
        equals: 'Les deux champs ne correspondent pas',
        default: 'Veuillez entrer une valeur correcte',
    });
    Pristine.setLocale('fr');
    validator.value = new Pristine($form);

    resetValidation($form);

    const isLastPage = activePage.value === data.value.form.pages.length - 1;
    const valid = runValidation(validator.value, $page.$el);

    if (!valid) {
        loading.value = false;
        alertState.value = 'error';
        return;
    }

    if (!isLastPage) {
        loading.value = false;
        activePage.value += 1;
        return;
    }

    const formData = getMutationVariables(data.value.form, $form);
    const formMutation = getFormMutation(data.value.form);

    try {
        const mutationData = await gqlClient.request(formMutation, formData);
        loading.value = false;

        const response = mutationData[`save_${props.handle}_Submission`];
        onSuccess(response);
    } catch (err) {
        loading.value = false;
        alertState.value = 'error';

        // Apply server-side errors
        applyServerValidation(validator.value, $form, err);

        console.error(err);
    }
};

// console.log(config);
</script>

<template>
    <div class="overflow-hidden">
        <form
            v-if="data.form"
            ref="formElement"
            novalidate
            v-on:submit.prevent="onSubmit"
        >
            <FormieAlert
                v-model="alertState"
                :success-text="config.settings.submitActionMessage ?? ''"
                :error-text="config.settings.errorMessage ?? ''"
            />

            <div v-if="data.form.displayFormTitle" class="text-center">
                <h2 class="my-4 text-xl font-semibold leading-6 text-gray-900 sm:my-6">
                    {{ form.title }}
                </h2>

                <hr>
            </div>

            <div v-if="data.form.displayPageTabs" class="border-b border-gray-200 px-4 sm:px-6">
                <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                    <a
                        v-for="(page, index) in data.form.pages"
                        :key="index"
                        href="#"
                        class="whitespace-nowrap border-b-2"
                        :class="{
                            'border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600' : index == activePage,
                            'border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700': index !== activePage
                        }"
                        v-on:click.prevent="onTabClick(index)"
                    >
                        {{ page.name }}
                    </a>
                </nav>
            </div>

            <div v-if="data.form.displayPageProgress" class="mt-4 px-4 sm:px-6">
                <div class="flex h-5 overflow-hidden rounded-md bg-gray-200 text-xs font-medium text-white">
                    <div
                        class="flex flex-col justify-center bg-indigo-500 text-center"
                        :style="'width: ' + stepPercentage + '%'"
                        role="progressbar"
                        :aria-valuenow="stepPercentage"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                        <span class="">{{ stepPercentage }}%</span>
                    </div>
                </div>
            </div>

            <FormiePage
                v-for="(page, index) in data.form.pages"
                :key="index"
                :ref="el => formPages.push(el)"
                v-model="activePage"
                :page="page"
                :page-index="index"
                :form="data.form"
                :loading="loading"
                :settings="config.settings.pages[index]"
            />
        </form>

        <div v-else-if="form === null" class="bg-perle p-24 text-center text-red-500">
            Unable to find form "{{ handle }}".
        </div>

        <div v-else class=" p-24 text-center text-red-500">
            <div v-if="error" class="text-red-500">
                {{ error }}
            </div>
            <div v-else class="loading loading-lg" />
        </div>
    </div>
</template>
